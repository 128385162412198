import { loadConfiguration } from '@emmysoft-gmbh/emmysoft-proxy'

export const env = {
  proxyUrl: window.VUE_APP_API_PROXY_SERVER_URL,
  orgId: window.VUE_APP_ORGANIZATION_ID
}

let configuration = undefined

export async function loadConfig() {
  configuration = await loadConfiguration(env.proxyUrl, 2, env.orgId)
  if (configuration.status === 404) {
    throw new Error('Not found')
  }
  console.log(configuration)
}

export { configuration }

<template>
  <div ref="snarkdown" class="markdown"></div>
</template>

<script>
import snarkdown from 'snarkdown'

export default {
  name: 'Snarkdown',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.render()
  },
  updated() {
    this.render()
  },
  methods: {
    render() {
      const ref = this.$refs.snarkdown
      ref.innerHTML = snarkdown(this.value)
    }
  }
}
</script>

<style scoped>
.markdown {
  text-align: left;
}
</style>

export default `# **EmmySoft – Datenschutzbedingungen / Informationen zum sensitiven Datenschutz von (potenziellen) Kandidat:innen / Allgemeine Informationen zum Umgang mit Ihren Daten**

Datenschutz und Diskretion sind der EmmySoft GmbH äußerst wichtig! Nicht nur durch die aktuellen Verordnungen nach EU-DSGVO / GDPR, sondern auch, weil wir besonders in unserer Branche in Verantwortung stehen, Sie und Ihre personenbezogenen Daten zu schützen.
 Unsere Dienstleistungen setzen einen vertrauensvollen Umgang mit Kunden- und (potenziellen) Kandidateninformationen voraus. In diesem Verhältnis werden wir selbstverständlich Diskretion und Ihre empfindlichen Daten höchstmöglich schützen. – Ihr EmmySoft-Team.

**Welche Daten erhebt EmmySoft GmbH?**

Um Sie und unsere Kunden in der Job- und Kandidatenauswahl zu unterstützen, erhebt EmmySoft GmbH (folgend auch EmmySoft, „wir&quot;, „uns&quot;) Ihren Namen, Ihre Kontaktangaben und andere relevante Informationen direkt aus Ihrem Lebenslauf. Zusätzlich erheben wir Informationen aus telefonischen und persönlichen Gesprächen mit Ihnen, die zur Einschätzung Ihrer Person und beruflichen Expertise dient.

**Wie nutzen wir Ihre personenbezogenen Daten?**

Wir nutzen die Daten, die wir erheben, um Ihnen einen bestmöglichen Service zu bieten. Dazu gehört, dass wir Ihre Daten für die manuelle und automatische Suche nach potenziellen Stellen auswerten und Sie über offene Vakanzen informieren. Wir können Ihre Daten auch verwenden, um Sie zu kontaktieren und Sie über Neuigkeiten und Promotions zu informieren.

**An wen geben wir Ihre personenbezogenen Daten weiter?**

Wir können Ihre Daten an alle Mitglieder der EmmySoft GmbH und an vertrauenswürdige Dritte weitergeben, um unsere Services für Sie bereitzustellen. Personenbezogene Daten und Unterlagen werden lediglich mit Ihrer Zustimmung an vertrauenswürdige Dritte weitergegeben. Unter bestimmten Umständen kann es vorkommen, dass wir von einer Aufsichts- oder Strafverfolgungsbehörde aufgefordert werden, bestimmte personenbezogene Daten offen zu legen.

**Wie lange speichern wir Ihre personenbezogenen Daten?**

Wir speichern Ihre personenbezogenen Daten nur so lange wie von Ihnen erwünscht. Darüber hinaus führen wir regelmäßige Datenbereinigungen und -aktualisierungen durch, um sicherzustellen, dass die uns vorliegenden Daten relevant und richtig sind.

**Welche Rechte haben Sie als (potenzielle/r) Kandidat:in?**

Sie haben die Kontrolle über Ihre Daten! Falls Sie keine E-Mails, Anrufe oder Kontaktaufnahmen jeglicher Art mehr von uns erhalten möchten, können Sie die Mitteilungen, die Sie erhalten, abbestellen bzw. abwählen. Sie können uns auch per E-Mail, telefonisch oder per Post kontaktieren, dann nehmen wir diese Änderung für Sie vor.

**Wie setzen wir Cookies ein?**

Wir wollen, dass Sie bei der Nutzung unserer Dienste ein bestmögliches Nutzererlebnis haben. Aus diesem Grund verwenden wir Cookies. Das sind kleine Textdateien, die auf Ihrem Computer gespeichert werden und uns helfen, unsere Websites und unsere Online-Services zu optimieren.

**Wie können Sie uns kontaktieren?**

Setzten Sie sich mit uns gerne in Verbindung, wenn Sie Fragen dazu haben, wie wir personenbezogene Daten verarbeiten, speichern und weitergeben. Dazu können Sie sich gerne an Ihren Berater bei EmmySoft GmbH wenden oder direkt über die E-Mail-Adresse gdpr@EmmySoft.com mit unserem Datenschutzbeauftragten in Kontakt treten.

# **Datenschutzrichtlinien der EmmySoft GmbH**

Recht auf Widerspruch: Sie haben ein gesetzliches Recht auf jederzeitigen Widerspruch gegen: (i) die Verwendung Ihrer personenbezogenen Daten zu Direktmarketingzwecken und (ii) die Verarbeitung Ihrer personenbezogenen Daten in unserem berechtigten Interesse, sofern nicht ein zwingender gesetzlicher Grund für eine Weiterverarbeitung durch uns vorliegt.

 Wir, die EmmySoft GmbH, sind dem Schutz der Privatsphäre unserer Kandidat:innen, Kunden und Nutzer unserer Website verpflichtet. Es ist uns ein Anliegen, eine sichere, geschützte Nutzererfahrung zu bieten. Deshalb sorgen wir dafür, dass die uns von Ihnen zur Verfügung gestellten Daten sowie Daten, die wir über verschiedene Kanäle (wie unsere Internetseiten, im Rahmen schriftlicher Korrespondenz einschl. E-Mail, in Gesprächen oder bei Besprechungen mit unseren Personalberatern oder über eines unserer Büros) erheben, nur für die in dieser Richtlinie festgelegten Zwecke verwendet werden.
 Wir möchten Sie mit dieser Datenschutzrichtlinie über die Arten von personenbezogenen Daten informieren, die wir von unseren Kandidat:innen erfassen, die Verwendungszwecke dieser Daten sowie deren Verarbeitung. Darüber hinaus sind wir zu einer Einhaltung der Transparenzvorschriften gemäß der EU-Datenschutz-Grundverordnung 2016/679 („DSGVO&quot;) und den nationalen Umsetzungsbestimmungen verpflichtet.

**Die von uns erhobenen Daten**

Wir werden Ihre personenbezogenen Daten wie u.a. Ihren Namen und Ihre Kontaktangaben sowie andere relevante Daten aus Ihrem Lebenslauf und sozialen Netzwerken (z.B. Xing, LinkedIn und weitere) erfassen. Wir können Ihre Angaben und Ihren Lebenslauf gegebenenfalls auf elektronischem Weg erhalten, z.B. im Rahmen einer Direktbewerbung auf einer unserer Internetseiten oder einer Bewerbung, die Sie uns über eine andere Stellenbörse geschickt haben.
 Es ist wahrscheinlich, dass wir im Laufe unserer Beziehung zu Ihnen weitere personenbezogene Daten über Sie erheben. Diese Daten können von Ihnen direkt oder von Dritten wie etwa Organisationen stammen, denen wir Ihren Lebenslauf geschickt haben oder mit denen Sie im Zuge einer Stellenbewerbung in Kontakt getreten sind.

**Wie wir Ihre personenbezogenen Daten verwenden**

Wir werden Ihre personenbezogenen Daten für unsere berechtigten Geschäftszwecke verwenden, wie u.a.:

1. Zur Erbringung von Dienstleistungen an Sie;
2. Zur Pflege unserer Geschäftsbeziehung, im Rahmen derer Sie Nutzer unserer Internetseite, Kunde oder Kandidat:in sind;
3. Zur Vorlage Ihres Lebenslaufs bei allgemeinen Bewerbungen, bei der Bewerbung um bestimmte Stellen oder zur Anmeldung für unsere Stellenbenachrichtigungen. In einem separaten Teil zu Ihrem Lebenslauf unten können Sie eine Erläuterung der zusätzlichen Verwendung und Weitergabe finden;
4. Zum Abgleichen Ihrer Angaben mit freien Stellen, um die am besten für Sie geeignete Position für Sie zu finden und personenbezogene Daten für Bewerbungszwecke an Kunden zu senden;
5. Um Ihre Angaben für künftige Beschäftigungsmöglichkeiten über die spezifische Rolle, wegen der Sie sich mit uns in Verbindung gesetzt haben, hinaus aufzubewahren;
6. Um Ihre Fragen zu beantworten;
7. Für das direkte Marketing von Dienstleistungen, um Sie über Neuigkeiten und Branchen-Updates, Events, Aktionen und Wettbewerbe, Berichte und anderes zu informieren. Bevor wir das tun, erhalten Sie die Option, den Erhalt solcher Kommunikationen abzulehnen; jede Mitteilung von uns enthält außerdem die Option, diese abzubestellen.
8. Um vertragliche Pflichten unseren Kunden gegenüber zu erfüllen;
9. Um personenbezogene Daten an Aufsichts- oder Strafverfolgungsbehörden weiterzugeben, falls wir dazu verpflichtet oder befugt sind;
10. Um Ihnen auf unserer Website personalisierte Inhalte zur Verfügung zu stellen, die Kommunikation in unseren E-Mails für Sie relevanter zu machen und den Service unserer Personalberatungskräfte passgerechter auf Sie zuzuschneiden, verfolgen wir Ihre Nutzung und Interaktion mit unserer Website und unseren E-Mails nach und zeichnen sie auf.

Unsere Internetseite verwendet einen Aufzeichnungsservice, im Rahmen dessen Mausklicks, Mausbewegungen, Scrolling und in Website-Formulare eingegebener Text aufgezeichnet werden können. Die von diesem Service erhobenen Daten dienen der Verbesserung der Nutzerfreundlichkeit unserer Website. Die erhobenen Daten werden gespeichert und für aggregierte und statistische Berichterstattungszwecke verwendet und nicht an Dritte weitergegeben.

Wir können in Übereinstimmung mit den örtlichen Vorschriften bestimmte sensible personenbezogene Daten (in GDPR als Sonderkategorien bezeichnet) verarbeiten, wenn Sie diese in Informationen einbeziehen, die Sie uns senden, z.B. wenn Sie Informationen über Ihre Gesundheit oder Religion in den Lebenslauf, den Sie uns senden, aufnehmen. Es kann auch erforderlich sein, dass wir in den Ländern, in denen dies gesetzlich vorgeschrieben oder erlaubt ist, eine Strafregisterprüfung gegen Ihre Daten durchführen. Wir verfügen über Verfahren, um die Verwendung und Offenlegung dieser sensiblen Daten zu beschränken, sofern dies nicht gesetzlich erlaubt oder vorgeschrieben ist.

**Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten**

Im Rahmen der DSGVO stützen wir uns in erster Linie auf die folgenden Rechtsgrundlagen zur Verarbeitung personenbezogener Daten von Kunden sowie Kandidat:innen:
 (a) Für den Abschluss oder die Erfüllung eines Vertrags erforderlich – um Pflichten zu erfüllen, zu deren Wahrnehmung wir im Rahmen der Bereitstellung einer Dienstleistung an Sie verpflichtet sind, oder um von Ihnen verlangte Schritte zu ergreifen, um einen Vertrag mit Ihnen abzuschließen, müssen wir Ihre personenbezogenen Daten verarbeiten.
 (b) Zur Erfüllung einer rechtlichen Pflicht – wir unterliegen bestimmten rechtlichen Anforderungen, im Rahmen derer wir gegebenenfalls Ihre personenbezogenen Daten verarbeiten müssen. Wir können außerdem gesetzlich verpflichtet sein, Ihre personenbezogenen Daten an eine Aufsichts- oder Strafverfolgungsbehörde weiterzuleiten.
 (c) Zur Wahrung berechtigter Interessen erforderlich – entweder wir oder ein Dritter müssen Ihre personenbezogenen Daten zur Wahrung berechtigter Interessen verarbeiten, sofern wir festgestellt haben, dass diese Interessen nicht durch Rechte oder Freiheiten wie u.a. dem Recht auf Schutz Ihrer personenbezogenen Daten aufgehoben werden. Unsere berechtigten Interessen umfassen die Beantwortung von Anfragen und Wünschen von Ihnen und Dritten, die Optimierung unserer Website und der Kundenerfahrung, Informationsbereitstellung über unsere Produkte und Dienstleistungen und die Gewährleistung, dass wir unsere Geschäftstätigkeit auf angemessene und effiziente Weise betreiben.
 (d) Einwilligung – in einigen Fällen können wir Ihre Einwilligung in die spezifische Verarbeitung Ihrer personenbezogenen Daten einholen.

**Wie wir Ihre personenbezogenen Daten zur Verfügung stellen**

Unter bestimmten Umständen stellen wir Ihre personenbezogenen Daten Dritten zur Verfügung. Angaben zu diesen Dritten sowie die Gründe für eine solche Zurverfügungstellung finden Sie nachstehend.

**Kunden**

Wir geben Ihre personenbezogenen Daten an Kunden weiter, die Stellenangebote haben, an denen Sie interessiert sind. Hierzu holen wir vor jeder Übermittlung separat Ihr Einverständnis ein.

**Vertrauenswürdige Dritte**

Wir werden Ihre personenbezogenen Daten sowie, falls erforderlich, Ihre sensiblen personenbezogenen Daten an vertrauenswürdige Dritte weitergeben, falls wir die Daten aufbewahrt haben, um Ihnen oder unseren Kunden jeweils verlangte Dienstleistungen zu erbringen, wie:

- Prüfung von Arbeitszeugnissen;
- Prüfung von Qualifikationen;
- Prüfung des Strafregisters (sofern dies gesetzlich vorgeschrieben oder erlaubt ist);
- Verifizierung von Angaben, die Sie aus Drittquellen zur Verfügung gestellt haben und/oder
- Psychometrische Auswertungen oder Fähigkeitsprüfungen

Vor der Weitergabe Ihrer Daten zum Zweck der oben genannten Punkte setzen wir Sie telefonisch oder per E-Mail in Kenntnis.
 Wir werden Ihre personenbezogenen Daten außerdem an Dritte weitergeben, die Funktionen in unserem Auftrag ausüben und uns Dienstleistungen erbringen, wie:

- Berufsberater;
- Datenanalytikprovider;
- IT-Berater, die Test- und Entwicklungsarbeiten an unseren Geschäftstechnologiesystemen durchführen;
- Marktforschungs- und Mailingagenturen und/oder
- Funktionskoordinatoren

Wir verlangen von diesen Dritten Mindeststandards bezüglich Vertraulichkeit und Datenschutz. Insoweit personenbezogene Daten Dritten außerhalb des Europäischen Wirtschaftsraums (folgend „EWR&quot;) zur Verfügung gestellt werden bzw. diese von außerhalb des EWR auf diese Daten zugreifen, werden wir dafür sorgen, dass genehmigte Sicherheitsvorkehrungen bestehen, wie die gebilligten Musterklauseln oder das EU/US-Privacy-Shield.

**Aufsichts- und Strafverfolgungsbehörden**

Wie oben bereits erwähnt, können wir bestimmte personenbezogene Daten auf Anfrage seitens einer Aufsichts- oder einer Strafverfolgungsbehörde an diese weitergeben, sofern dies gemäß DSGVO und anderen Gesetzen erlaubt ist.

**Neue Unternehmenseigentümer**

Falls wir mit einem anderen Konzern oder Unternehmen fusionieren oder von diesem übernommen werden, geben wir Ihre personenbezogenen Daten an die neuen Eigentümer des Unternehmens bzw. Konzerns und deren Berufsberater weiter. In einem solchen Fall werden Sie entsprechend benachrichtigt.

**Wie lange wir Ihre Daten aufbewahren**

Die Dauer, für die wir Ihre personenbezogenen Daten halten bzw. speichern, hängt von den Dienstleistungen ab, die wir für Sie erbringen und wie lange Sie diese benötigen. Da wir in vielen Fällen Kandidat:innen über viele Jahre hinweg und potenziell während ihrer gesamten Laufbahn Stellen vermitteln, handelt es sich bei dem Zweck für die Aufbewahrung der Bewerberdaten oft um einen anhaltenden Zweck. Wir führen eine regelmäßige Bereinigung und Aktualisierung der Daten mit unseren Kandidat:innen durch, um sicherzustellen, dass (a) die von uns gehaltenen Daten korrekt sind und (b) wir Daten nicht zu lange aufbewahren.
 Unsere allgemeinen Geschäftsbedingungen sehen vor, dass unsere Kunden die Daten unserer Kandidat:innen, z.B. Lebensläufe, für die Zwecke der jeweiligen Position aufbewahren, um die sie sich bewerben. Außerdem sind unsere Kunden als die für die Verarbeitung Ihrer Daten Verantwortlichen angehalten, dafür zu sorgen, dass Sie über die Aufbewahrung Ihrer Daten informiert werden, wenn der Kunde Sie über potenzielle künftige Positionen informieren, Ihre Daten anderweitig halten oder zu anderen Zwecken verwenden möchte.

**Ihre Rechte in Bezug auf Daten, die wir über Sie speichern**

Sie haben in Bezug auf die personenbezogenen Daten, die wir über Sie halten, bestimmte Rechte. Genaue Angaben zu diesen Rechten und deren Ausübung sind nachstehend erläutert. Ehe wir Ihrer Anfrage nachkommen können, benötigen wir einen Nachweis Ihrer Identität.

**Recht auf Zugang**

Sie haben das Recht, jederzeit eine Kopie der von uns gehaltenen personenbezogenen Daten von uns anzufordern. Wenn wir berechtigte Gründe haben und die DSGVO dies zulässt, können wir Ihre Anfrage bezüglich Ihrer personenbezogenen Daten oder bestimmte Elemente dieser Anfrage ablehnen. Bei Ablehnung Ihrer Anfrage bzw. eines Teils derselben werden wir Ihnen unsere Gründe dafür mitteilen.

**Recht auf Berichtigung oder Vervollständigung**

Sind personenbezogene Daten, die wir in Bezug auf Sie halten, nicht korrekt, nicht aktuell oder unvollständig, haben Sie Anspruch auf Berichtigung, Aktualisierung oder Vervollständigung der Daten. Teilen Sie uns dies mit, indem Sie uns mithilfe einer der im Kontaktbereich unten angegebenen Methoden kontaktieren.

**Recht auf Löschung**

Unter bestimmten Umständen sind Sie berechtigt, die Löschung von personenbezogenen Daten, die wir über Sie halten, zu verlangen, z.B. wenn die Daten nicht mehr für Zwecke, zu denen sie erhoben oder verarbeitet wurden, benötigt werden, oder die Verarbeitung der Daten auf Ihrer Einwilligung beruht und keine anderen Rechtsgrundlagen bestehen, auf denen wir eine Verarbeitung der Daten vornehmen können.

**Recht auf Widerspruch gegen die oder Beschränkung der Verarbeitung**

Unter gewissen Umständen haben Sie das Recht, Widerspruch gegen unsere Verarbeitung Ihrer personenbezogenen Daten einzulegen, indem Sie uns dies mithilfe einer der im Kontaktbereich unten angegebenen Methoden mitteilen. Das kann z.B. der Fall sein, wenn wir Ihre Daten auf der Grundlage unserer berechtigten Interessen verarbeiten und keine zwingenden gesetzlichen Gründe für unsere Verarbeitung vorliegen, die Vorrang vor Ihren Rechten und Interessen haben. Sie haben außerdem das Recht, Widerspruch gegen die Verwendung Ihrer personenbezogenen Daten für Direktmarketingzwecke einzulegen.
 Darüber hinaus haben Sie gegebenenfalls das Recht, unsere Verwendung Ihrer personenbezogenen Daten einzuschränken, z.B. wenn Sie die Korrektheit der Daten in Frage gestellt haben und während des Zeitraums, in dem wir die Korrektheit überprüfen.

**Recht auf Datenübertragbarkeit**

Unter bestimmten Umständen haben Sie ein Recht auf Erhalt personenbezogener Daten, die wir über Sie halten, in strukturiertem, häufig verwendetem und maschinenleserlichem Format.
 Sie können von uns verlangen, dass wir solche Daten an Sie oder direkt an eine Drittorganisation übertragen.
 Das oben genannte Recht besteht nur in Bezug auf personenbezogene Daten, die:

- Sie uns zu einem früheren Zeitpunkt zur Verfügung gestellt haben und
- die von uns in automatisierter Form verarbeitet werden.

Wir kommen solchen Anfragen gern nach, können jedoch eine technische Kompatibilität mit den Systemen einer Drittorganisation nicht garantieren. Außerdem können wir keinen Anfragen nachkommen, die sich auf personenbezogene Daten Dritter beziehen, ohne dass uns eine Einwilligung dieser Dritten vorliegt.
 Sie können die oben genannten Rechte ausüben, indem Sie sich mithilfe einer der im Kontaktbereich unten angegebenen Methoden an uns wenden.
 Die meisten der oben genannten Rechte unterliegen Beschränkungen und Ausnahmen. Wir werden Gründe angeben, falls es uns nicht möglich ist, Anfragen bezüglich der Inanspruchnahme Ihrer Rechte nachzukommen.

**Einwilligung**

Insoweit wir Ihre personenbezogenen Daten auf der Grundlage Ihrer Einwilligung verarbeiten, haben Sie das Recht, diese Einwilligung jederzeit zu widerrufen. Teilen Sie uns dies mit, indem Sie uns mithilfe der Angaben im Kontaktbereich unten kontaktieren.

**Stellenbenachrichtigungen**

Um sich für Stellenbenachrichtigungen anzumelden, müssen Sie uns Ihren Namen und Ihre E-Mail-Adresse oder Telefonnummer mitteilen, die wir dann verwenden, um Sie per E-Mail oder telefonisch über die jüngsten Stellenangebote in der von Ihnen angegebenen Branche auf dem Laufenden zu halten und Ihnen Neuigkeiten aus der Branche und andere unsere Dienstleistungen betreffenden Informationen zur Verfügung zu stellen.
 Die Stellenbenachrichtigungen werden von uns oder einem Unternehmen innerhalb unserer Unternehmensgruppe verschickt. Befindet sich der Versender außerhalb des EWR, werden Ihre Angaben dieser Organisation übermittelt oder die Organisation greift direkt auf Ihre Angaben zu, um eine Versendung der relevanten Stellenbenachrichtigung an Sie zu ermöglichen.

**Lebenslauf**

Wir geben Ihnen die Möglichkeit, Ihren Lebenslauf über unsere Website einzureichen. Alternativ dazu können Sie Ihren Lebenslauf einfach einer unserer Personalberatungskräfte geben. Damit können Sie sich entweder für ein bestimmtes Stellenangebot bewerben oder Sie können den Lebenslauf zur Ansicht durch unsere Personalberater für künftige Positionen einreichen.
 Wir werden Ihren Lebenslauf in unserer Datenbank aufbewahren, auf die unsere Personalberatungskräfte zugreifen können.
 Ihr Lebenslauf kann außerdem direkt an andere Unternehmen innerhalb unserer Unternehmensgruppe weitergeleitet werden, die ihn prüfen und in ihre Datenbank einpflegen, um Ihnen ihrerseits Dienstleistungen bereitstellen zu können. Für diesen Vorgang holen wir vorab Ihre Einverständniserklärung ein. Sie können Ihre Einwilligung in eine Verarbeitung Ihrer Daten außerhalb des EWR nach Ausfüllen Ihres Anmeldeformulars bei uns widerrufen.
 Ihren Lebenslauf können Sie jederzeit aktualisieren, indem Sie dem gleichen Ablauf folgen, um einen neuen Lebenslauf einzureichen. Ihr alter Lebenslauf wird dann, sofern die Einreichungsangaben die gleichen bleiben (z.B., wenn Sie beide Lebensläufe von der gleichen E-Mail-Adresse aus eingereicht oder Ihren Ansprechpartner über Ihre neue Einreichung informiert haben), automatisch archiviert.

**Verwendung von Cookies**

Wie die meisten Websites platzieren wir bei Ihrem Besuch unserer Website Cookies auf Ihrem Computer oder anderen Geräten. Diese Cookies werden in erster Linie dazu verwendet, die Funktionalität der Websites zu unterstützen, das Nutzererlebnis zu verbessern oder es uns zu ermöglichen, unsere Websites zu optimieren, den Besucherverkehr zu messen oder andere interne Managementzwecke zu erfüllen.
 Wir platzieren zielgerichtete, relevante Bannerwerbung auf einer Reihe anerkannter Whitelist-Websites auf der ganzen Welt, um Ihnen Stellenangebote und Inhalte vermitteln zu können, von denen wir glauben, dass sie für Sie von Interesse sind. Die zielgerichtete Platzierung basiert auf Ihren bisherigen Interaktionen mit unserer Website, unseren E-Mails und Ihrem Kontakt zu unseren Personalberatern. Ihre Interaktion mit unserer Werbung kann herangezogen werden, um die Wirksamkeit unserer Werbekampagnen zu messen und unsere Marketingstrategie im Einklang mit unseren berechtigten Interessen zu verbessern. Es findet keine Erfassung Ihrer identifizierbaren personenbezogenen Daten durch Werbekampagnen oder Werbe-Tracking-Techniken statt.

**Beschwerden**

Falls Sie mit unserer Verwendung Ihrer personenbezogenen Daten nicht einverstanden sind, können Sie sich unter Verwendung der Angaben im nachstehenden Kontaktbereich an uns wenden. Sie können außerdem eine Beschwerde bei der zuständigen Datenschutzbehörde einreichen:
 Telefon: +49 228 997799 0
 Website: http://www.bfdi.bund.de/
 Post: Die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit Husarenstraße 30 53117 Bonn
 Falls Sie außerhalb Deutschlands leben oder arbeiten oder eine Beschwerde hinsichtlich unserer Tätigkeit außerhalb Deutschlands haben, können Sie diese Beschwerde auch an eine andere Aufsichtsbehörde richten. Eine Liste der einschlägigen Behörden im EWR und in der Europäischen Freihandelszone finden Sie [hier](http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm).

**Andere Websites**

Bitte beachten Sie, dass ein Anklicken von Links und Bannerwerbung auf unseren Websites dazu führen kann, dass Ihr Browser auf eine Dritt-Website zugreift, auf der andere Datenpraktiken gelten als bei uns.
 Wir haften nicht für und haben keinen Einfluss auf Daten, die Sie diesen Dritten übermitteln oder die von diesen erhoben werden. Deshalb raten wir Ihnen, deren Datenschutzrichtlinie zu lesen.

**Internetbasierte Übermittlung**

Angesichts der Tatsache, dass das Internet ein globales Umfeld ist, ist die Erhebung und Verarbeitung personenbezogener Daten zwangsläufig mit einer internationalen Übermittlung von Daten verbunden. Leider ist die Übermittlung von Daten über das Internet nicht hundertprozentig sicher. Wir werden zwar unser Bestes tun, um Ihre personenbezogenen Daten zu schützen, können die Sicherheit Ihrer über Netzwerke Dritter an unsere Website übermittelten Daten jedoch nicht garantieren; alle Übermittlungen erfolgen auf Ihre Gefahr. Sobald wir die Daten erhalten haben, wenden wir strenge Verfahren und Sicherheitsfunktionen in dem Bemühen an, unbefugten Zugriff zu verhindern.

**Änderungen unserer Datenschutzrichtlinie**

Diese Datenschutzrichtlinie kann von EmmySoft GmbH jederzeit geändert werden. Bei Änderung unserer Datenschutzrichtlinie in der Zukunft werden wir Sie über wesentliche Änderungen oder Aktualisierungen unserer Datenschutzrichtlinie per E-Mail unterrichten, sofern Sie uns eine E-Mail-Adresse zur Verfügung gestellt haben.

**Gleichstellung**

Die Firma legt als Arbeitgeber großen Wert auf Chancengleichheit und hat sich der Vielfalt (Diversität) verpflichtet. Das bedeutet, dass wir alle Stellenbewerber und -Bewerberinnen und Mitarbeiterinnen und Mitarbeiter gleichbehandeln und niemanden aufgrund von Geschlecht, Ehestand, Rasse, ethnischer Herkunft, Hautfarbe, Nationalität, nationaler Herkunft, Behinderung, sexueller Orientierung, Religion oder Alter diskriminieren.
 Im Zuge unserer Verpflichtung zur Gleichstellung werden wir die von Ihnen zur Verfügung gestellten Daten von Zeit zu Zeit zum Zwecke des Diversitätsmonitoring verwenden. Die Verwendung dieser Daten erfolgt grundsätzlich auf anonymisierter Basis.

**Kontakt**

Falls Sie Fragen haben oder sich hinsichtlich unserer Verarbeitung Ihrer personenbezogenen Daten in Verbindung setzen möchten, einschließlich einer Ausübung Ihrer oben beschriebenen Rechte, kontaktieren Sie uns per E-Mail oder Post. Wenn Sie uns kontaktieren, werden wir Sie auffordern, Ihre Identität zu bestätigen.

**E-Mail:**

[gdpr@emmysoft.com](mailto:gdpr@emmysoft.com)

**Postanschrift:**

EmmySoft GmbH
 z.H. Datenschutzbeauftragter
 Bremer Straße 67
 40221 Düsseldorf

**Ansprechpartner:**

Thomas Jäckel (Datenschutzbeauftragter)

Unser Gesellschaftssitz ist:
 EmmySoft GmbH
 Bremer Straße 67
 40221 Düsseldorf`;

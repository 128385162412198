import gdpr_de from './locales/gdpr.de'
import gdpr_en from './locales/gdpr.en'

import en from './locales/en'
import de from './locales/de'

export default {
  de: {
    mainText: gdpr_de,
    ...de
  },
  en: {
    mainText: gdpr_en,
    ...en
  }
}

import { loadConfig, env } from './config/env'

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

;(async () => {
  try {
    await loadConfig()
  } catch {
    console.error(`Application is misconfigured. Could not retrive configuration for ${env.orgId} from ${env.proxyUrl}`)
    return
  }
  app.mount('#app')
})()

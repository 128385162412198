<template>
  <div id="langSelector">
    <div v-for="language in languages" :key="language" class="language" :class="{ active: language === modelValue }">
      <img :src="icon(language)" @click="emitChange(language)" />
    </div>
  </div>
</template>

<script>
import i18n from '../i18n.js'

export default {
  name: 'Snarkdown',
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      i18n: i18n
    }
  },
  emits: ['update:modelValue'],
  computed: {
    languages() {
      return Object.keys(this.i18n)
    }
  },
  methods: {
    emitChange(languageKey) {
      this.$emit('update:modelValue', languageKey)
    },
    icon(language) {
      return this.i18n[language].icon
    },
    name(language) {
      return this.i18n[language].name
    }
  }
}
</script>

<style scoped>
#langSelector {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  background-color: white;
  border-radius: 6px;
}

.language {
  margin: 12px;
  margin-bottom: 6px;
  transition: all ease-in-out 200ms;
}

.language:not(.active) {
  opacity: 0.5;
  cursor: pointer;
}

.language:not(.active):hover {
  opacity: 1;
}
</style>

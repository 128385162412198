export default `# **EmmySoft – Privacy Policy / Information on the sensitive data protection of (potential) candidates / General information on handling your data**

Data protection and discretion are extremely important to EmmySoft GmbH! Not only because of current regulations according to EU-DSGVO / GDPR, but rather because we are in responsibility to protect you and your personal data, especially in our industry.

Our services require a trusted handling of customer and (potential) candidate information. In this matter we will be discreet and protect your sensitive data to the highest possible extent - Your EmmySoft team.

**What data does EmmySoft GmbH collect?**

In order to support you and our clients in the selection of jobs and candidates, EmmySoft GmbH (hereinafter also EmmySoft, &quot;we&quot;, &quot;us&quot;) collects your name, contact details and other relevant information directly from your resume. In addition, we collect information from telephone and personal interviews with you, which is used to assess your personality and professional expertise.

**How do we use your personal data?**

We use the data we collect to provide you with the best possible service. This includes evaluating your data for manual and automated searches for potential jobs and informing you about open vacancies. We may also use your data to contact you and inform you of news and promotions.

**Who do we share your personal data with?**

We may share your information with all members of EmmySoft GmbH and trusted third parties to provide our services to you. Personal data and documents will only be shared with trusted third parties with your consent. Under certain circumstances, we may be required by a regulatory or law enforcement agency to disclose certain personal information.

**How long do we store your personal data?**

We store your personal data only for as long as you request. We also perform periodic data cleansing and updates to ensure that the data we have is relevant and accurate.

**What rights do you have as a (potential) candidate?**

You have control over your data! If you no longer wish to receive emails, phone calls or contact of any kind from us, you can opt-out or opt-in of the communications you receive. You can also contact us by email, phone or mail and we will make this change for you.

**How do we use cookies?**

We want you to have the best possible user experience when using our services. For this reason, we use cookies. These are small text files that are stored on your computer and help us optimize our websites and our online services.

**How can you contact us?**

Feel free to contact us if you have any questions about how we process, store and share personal data. To do so, you are welcome to contact your advisor at EmmySoft GmbH or contact our data protection officer directly via the e-mail address [gdpr@EmmySoft.com](mailto:gdpr@EmmySoft.com).

# **Data protection guidelines of EmmySoft GmbH**

Right to object: You have a legal right to object at any time to: (i) the use of your personal data for direct marketing purposes and (ii) the processing of your personal datain our legitimate interest, unless there is a compelling legal reason demanding further processing by us.

We, EmmySoft GmbH, are committed to protecting the privacy of our candidates, customers and users of our website. It is our concern to provide a safe, secure user experience. Therefore, we ensure that the information you provide to us, as well as information we collect through various channels (such as our websites, in written correspondence including email, in conversations or meetings with our recruiters, or through one of our offices), is used only for the purposes set forth in this policy.

The purpose of this Privacy Policy is to inform you about the types of personal data we collect from our candidates, the purposes for which we use this data, and how we process it. In addition, we are committed to compliance with transparency requirements under the EU General Data Protection Regulation 2016/679 (&quot;GDPR&quot;) and national implementing legislation.

**The data we collect**

We will collect your personal data such as your name and contact details and other relevant data from your CV and social networks (e.g., Xing, LinkedIn and others). We may receive your information and resume electronically, for example, as part of a direct application on one of our websites or an application you sent to us through another job board.

It is likely that we will collect other personal data about you in the course of our relationship with you. This data may come from you directly or from third parties such as organizations we have sent your resume to or with whom you were in contact in the course of applying for a job.

**How we use your personal data**

We will use your personal data for our legitimate business purposes, such as, but not limited to:

1. To provide services to you;

2. To maintain our business relationship in which you are a user of our website, a customer or a candidate;

3. To submit your resume for general job applications, to apply for specific positions, or to sign up for our job notifications. See a separate section on your resume below for an explanation of additional uses and sharing;

4. To match your information with job openings to find the most suitable position for you and to send personal information to customers for application purposes;

5. To retain your information for future employment opportunities beyond the specific role you contacted us about;

6. To respond to your questions;

7. For direct marketing of services to inform you of news and industry updates, events, promotions and contests, reports and other. Before we do so, you will be given the option to opt out of receiving such communications; each communication from us will also include the option to opt out.

8. To fulfill contractual obligations to our customers;

9. To disclose personal information to regulatory or law enforcement authorities if we are required or authorized to do so;

10. In order to provide you with personalized content on our website, to make communications in our emails more relevant to you, and to tailor our recruiters&#39; services more appropriately for you, we track and record your use of and interaction with our website and emails.

Our website uses a recording service that may record mouse clicks, mouse movements, scrolling, and text entered into website forms. The data collected by this service is used to improve the user experience of our website. The data collected is stored and used for aggregate and statistical reporting purposes and is not shared with third parties.

We may process certain sensitive personal data (referred to as special categories in GDPR) in accordance with local regulations if you include it in information you send us, for example, if you include information about your health or religion in the resume you send us. We may also be required to conduct a criminal records check against your information in countries where it is required or permitted by law. We have procedures in place to restrict the use and disclosure of this sensitive data unless permitted or required by law.

**Legal basis for processing your personal data**

Under the GDPR, we rely primarily on the following legal bases to process personal data of clients as well as candidates:

(a) Necessary for the conclusion or performance of a contract - in order to perform duties which we are obliged to perform in the course of providing a service to you, or in order to take steps requested by you to conclude a contract with you, we need to process your personal data.

(b) To comply with a legal obligation - we are subject to certain legal requirements under which we may need to process your personal data. We may also be required by law to disclose your personal data to a supervisory or law enforcement authority.

(c) Necessary for the purposes of legitimate interests - either we or a third-party must process your personal data for the purposes of legitimate interests, provided that we have determined that those interests are not overridden by rights or freedoms such as, among others, the right to the protection of your personal data. Our legitimate interests include responding to inquiries and requests from you and third parties, optimizing our website and customer experience, providing information about our products and services, and ensuring that we operate our business in an appropriate and efficient manner.

(d) Consent - in some cases, we may seek your consent for the specific processing of your personal data.

**How we provide your personal data**

In certain circumstances, we may make your personal data available to third parties. Details of these third parties and the reasons for such provision are set out below.

**Customers**

We share your personal data with customers who have job openings in which you are interested. For this purpose, we obtain your consent separately before each transfer.

**Trusted third parties**

We will share your personal data and, if necessary, your sensitive personal data with trusted third parties if we have retained the data in order to provide you or our customers with services requested from time to time, such as:

- Employment reference checks;
- Verification of qualifications;
- Criminal records check (where required or permitted by law);
- Verification of information provided by you from third-party sources and/or
- Psychometric evaluations or skills testing

We will notify you by telephone or email prior to sharing your information for the purposes above.

We will also share your personal information with third parties who perform functions on our behalf and provide services to us, such as:

- Career counselors;
- Data analytics providers;
- IT consultants who perform testing and development work on our business technology systems;
- Market research and mailing agencies and/or
- Functional Coordinators

We require these third parties to meet minimum standards of confidentiality and data protection. To the extent that personal data is provided to or accessed by third parties outside the European Economic Area (hereinafter &quot;EEA&quot;), we will ensure that approved safeguards are in place, such as the approved model clauses or the EU/US Privacy Shield.

**Regulatory and law enforcement authorities**

As noted above, we may disclose certain personal data to a supervisory or law enforcement authority upon their request, where permitted under the GDPR and other laws.

**New Company Owners**

If we merge with or are acquired by another group or company, we will share your personal data with the new owners of the company or group and their professional advisors. In such a case, you will be notified accordingly.

**How long we keep your data**

The length of time we hold or store your personal data depends on the services we provide to you and how long you require them. In many cases, because we place candidates in jobs for many years and potentially throughout their careers, the purpose for retaining candidate data is often ongoing. We conduct regular data cleansing and updating with our candidates to ensure that (a) the data we hold is accurate and (b) we do not retain data for too long.

Our Terms and Conditions require that our clients retain our candidates&#39; data, such as resumes, for the purposes of the particular position for which they are applying. In addition, our clients, being responsible for your data, are required to ensure that you are informed about the retention of your data if the client wishes to inform you about potential future positions, otherwise hold your data or use it for other purposes.

**Your rights regarding the data we hold about you**

You have certain rights in relation to the personal data we hold about you. Details of these rights and how to exercise them are set out below. Before we can honor your request, we will need proof of your identity.

**Right of access**

You have the right to request a copy of the personal data we hold about you at any time. If we have legitimate grounds and the GDPR permits, we may refuse your request regarding your personal data or certain elements of that request. If we refuse your request or any part of it, we will provide you with our reasons for doing so.

**Right to rectification or completion**

If personal data we hold about you is inaccurate, not up to date or incomplete, you have the right to have it corrected, updated or completed. Let us know by contacting us using one of the methods indicated in the contact section below.

**Right to erasure**

In certain circumstances, you have the right to request the erasure of personal data we hold about you, for example, if the data is no longer needed for the purposes for which it was collected or processed, or the processing of the data is based on your consent and there are no other legal bases on which we can process the data.

**Right to object to or restrict processing**

In certain circumstances, you have the right to object to our processing of your personal data by notifying us using one of the methods indicated in the contact section below. This may be the case, for example, when we are processing your data on the basis of our legitimate interests and there are no compelling legal grounds for our processing that override your rights and interests. You also have the right to object to the use of your personal data for direct marketing purposes.

In addition, you may have the right to restrict our use of your personal data, for example, if you have questioned the accuracy of the data and during the period in which we verify the accuracy.

**Right to data portability**

In certain circumstances, you have a right to receive personal data we hold about you in a structured, commonly used and machine-readable format.

You may request that we transfer such data to you or directly to a third-party organization.

The above right exists only with respect to personal data that:

- You have provided to us at an earlier point in time and
- which is processed by us in automated form.

We are happy to comply with such requests, but we cannot guarantee technical compatibility with a third-party organization&#39;s systems. In addition, we cannot comply with requests that relate to personal data of third parties without the consent of such third parties.

You may exercise the above rights by contacting us using one of the methods indicated in the contact section below.

Most of the rights listed above are subject to limitations and exceptions. We will provide reasons if it is not possible for us to comply with requests regarding the exercise of your rights.

**Consent**

To the extent that we process your personal data based on your consent, you have the right to withdraw this consent at any time. Let us know by contacting us using the details in the contact section below.

**Job Notifications**

To sign up for job alerts, you must provide us with your name and email address or phone number, which we will then use to keep you informed by email or phone about the latest job openings in the industry you specify and to provide you with industry news and other information related to our services.

Job notifications are sent by us or a company within our group of companies. If the sender is outside the EEA, your details will be passed to that organization or the organization will access your details directly to enable the relevant job notification to be sent to you.

**Resume**

We give you the option to submit your resume via our website. Alternatively, you can simply give your resume to one of our recruiters. This will allow you to either apply for a specific job opportunity or submit the resume for our recruiters to view for future positions.

We will keep your resume in our database, which can be accessed by our recruiters.

Your resume may also be forwarded directly to other companies within our group of companies who will review it and add it to their database so that they in turn can provide services to you. For this process we will ask for your consent beforehand. You may withdraw your consent for your data to be processed outside the EEA after completing your application form with us.

You may update your resume at any time by following the same process to submit a new resume. Your old resume will then be automatically archived, provided the submission details remain the same (for example, if you submitted both resumes from the same email address or informed your contact of your new submission).

**Use of cookies**

Like most websites, we place cookies on your computer or other devices when you visit our website. These cookies are primarily used to support the functionality of the Websites, improve the user experience, or enable us to optimize our Websites, measure visitor traffic, or for other internal management purposes.

We place targeted, relevant banner advertisements on a number of recognized whitelisted websites around the world in order to provide you with job opportunities and content that we believe will be of interest to you. Targeted placement is based on your past interactions with our website, our emails and your contact with our recruiters. Your interaction with our advertising may be used to measure the effectiveness of our advertising campaigns and to improve our marketing strategy consistent with our legitimate interests. There is no collection of your identifiable personal information through advertising campaigns or advertising tracking techniques.

**Complaints**

If you do not agree with our use of your personal data, you may contact us using the details provided in the contact section below. You can also file a complaint with the relevant data protection authority:

Phone: +49 228 997799 0

Website: http://www.bfdi.bund.de/

Mail: The Federal Commissioner for Data Protection and Freedom of Information Husarenstraße 30 53117 Bonn, Germany.

If you live or work outside of Germany or have a complaint regarding our activities outside of Germany, you may also submit this complaint to another supervisory authority. A list of relevant authorities in the EEA and the European Free Trade Association can be found [here](https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm).

**Other websites**

Please note that clicking on links and banner advertisements on our websites may cause your browser to access a third-party website that has different data practices than ours.

We are not responsible for and have no control over data you submit to or that is collected by these third parties. Therefore, we advise you to read their privacy policy.

**Internet-based transmission**

Given that the Internet is a global environment, the collection and processing of personal data inevitably involves the international transfer of data. Unfortunately, the transmission of data over the Internet is not one hundred percent secure. While we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our website via third-party networks; all transmissions are at your risk. Once we receive the data, we apply strict procedures and security features in an effort to prevent unauthorized access.

**Changes to our Privacy Policy**

This privacy policy may be changed by EmmySoft GmbH at any time. If we change our privacy policy in the future, we will notify you of any material changes or updates to our privacy policy by email if you have provided us with an email address.

**Equality**

As an employer, the company places great importance on equal opportunities and is committed to diversity. This means that we treat all job applicants and employees equally and do not discriminate against anyone on the basis of gender, marital status, race, ethnicity, color, national origin, national origin, disability, sexual orientation, religion or age.

As part of our commitment to equality, we will use the data you provide from time to time for diversity monitoring purposes. This data will generally be used on an anonymous basis.

**Contact**

If you have any questions or would like to get in touch regarding our processing of your personal data, including an exercise of your rights described above, please contact us by email or mail. When you contact us, we will ask you to confirm your identity.

**Email:**

[gdpr@emmysoft.com](mailto:gdpr@emmysoft.com)

**Mail:**

EmmySoft GmbH

Attn: Data protection officer

Bremer Street 67

40221 Duesseldorf

Germany

**Contact person:**

Thomas Jäckel (Data Protection Officer)

Our registered office is:

EmmySoft GmbH

Bremer Street 67

40221 Duesseldorf`;
